import React from "react";
import Home from "../components/HomePage/Home";

const IndexPage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
